import React from "react"
import { graphql } from "gatsby"

import { Layout } from "../components/layout"
import UniversityTemplateLayout from "../components/UniversityTemplate/UniversityTemplateLayout"
import styled from "styled-components"
import Breadcrumb from "../components/Breadcrumb"
import UniversityLeftCol from "../components/UniversityTemplate/UniversityLeftCol"
import UniversityRightCol from "../components/UniversityTemplate/UniversityRightCol"
import { GatsbyImage } from "gatsby-plugin-image"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import { Container } from "@material-ui/core"
interface Props {
  data: any
  pageContext: any
}
const UniversityTemplate = ({ data, pageContext }: Props) => {
  const partners = data.allWpPartner.edges.map(v => v.node)
  const uniInfo = data.wpUniversity
  const studentInfo = data.allWpPortfolio.edges.map(v => v.node)
  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Universities",
      url: "/universities",
    },
    {
      title: uniInfo.title,
      url: "/universities/" + uniInfo.slug,
    },
  ]
  const blocks = LazyBlockFactory(uniInfo.lazy_data, data.wpUniversity.title)
  let uniLogo
  let featureimage

try{   
  uniLogo = uniInfo.UniversityInfo.universityLogo.localFile.childImageSharp.gatsbyImageData
  featureimage = uniInfo.UniversityInfo.featuredimage.localFile.childImageSharp.gatsbyImageData
}catch(e){
  // console.log(e)
}
   return (
    <Layout partners={partners} title={"Graduate Fashion Week - " + data.wpUniversity.title}>
      <div style={{ backgroundColor: "#fafafa", width: "100%" }}>
        <Container maxWidth={"lg"}>
          {/* <BreadCrumbContainer> */}
          <Breadcrumb data={breadcrumbData} />
          {/* </BreadCrumbContainer> */}
        </Container>
      </div>
      <UniversityTemplateLayout>
        <UniversityLeftCol
          title={uniInfo.title}
          img={uniLogo}
          phone={uniInfo.UniversityInfo.contactNumber}
          email={uniInfo.UniversityInfo.contactEmail}
          website={uniInfo.UniversityInfo.websiteLink}
          instagram={uniInfo.UniversityInfo.instagramLink}
          twitter={uniInfo.UniversityInfo.twitter}
          facebook={uniInfo.UniversityInfo.facebook}
        />
        <UniversityRightCol
          title={uniInfo.title}
          phone={uniInfo.UniversityInfo.contactNumber}
          email={uniInfo.UniversityInfo.contactEmail}
          website={uniInfo.UniversityInfo.websiteLink}
          google_maps_link={uniInfo.UniversityInfo.googleMapsLink}
          full_address={uniInfo.UniversityInfo.fullAddress}
          students={studentInfo}
        >
          <h1 style={{ maxWidth: 650, paddingBottom: 30 }}>{uniInfo.title}</h1>
          <GatsbyImage image={featureimage} alt="Uni logo" style={{ width: "100%", maxHeight: 570, marginBottom: 30 }} />
          {blocks.map(v => v)}
        </UniversityRightCol>
      </UniversityTemplateLayout>
    </Layout>
  )
}

export default UniversityTemplate

const BreadCrumbContainer = styled(Container)`
  border: 1px solid red;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #fafafa;
`
export const pageQuery = graphql`
  query universityById($id: String!) {
    wpUniversity(id: { eq: $id }) {
      id
      title
      slug
      UniversityInfo {
        contactEmail
        contactNumber
        facebook
        fieldGroupName
        instagramLink
        twitter
        websiteLink
        fullAddress
        googleMapsLink
        featuredimage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        universityLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, layout: CONSTRAINED)
            }
          }
        }
      }
      lazy_data
    }
    allWpPartner {
      edges {
        node {
          id
          title
          slug
          PartnerInfo {
            tags
            partnershipIntro
            partnershipLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }

    allWpPortfolio {
      edges {
        node {
          id
          title
          slug
          student_portfolio {
            aboutMeHeading
          }
          student_profile {
            studentName
            specialism

            universitySchool {
              ... on WpUniversity {
                title
                UniversityInfo {
                  universityLocationCityForSearch
                }
              }
            }
            searchImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
