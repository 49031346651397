import { Container } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import styled from "styled-components"

const UniversityTemplateLayout: FunctionComponent = ({ children }) => {
  return <MainGrid maxWidth={"lg"}>{children}</MainGrid>
}

export default UniversityTemplateLayout

const MainGrid = styled(Container)`
  /* border: 1px solid black; */
  padding-top: 50px;
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 40px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: flex !important;
    flex-direction: column !important;
  }
`
