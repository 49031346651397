import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Arrow from "../../assets/svg/long-arrow-left.svg"
import { UniversityLeftColumnProps } from "./universityTypes"
import { GatsbyImage } from "gatsby-plugin-image"
import IconButton from "@material-ui/core/IconButton"
import TwitterIcon from "@material-ui/icons/Twitter"

import InstagramIcon from "@material-ui/icons/Instagram"

import FacebookIcon from "@material-ui/icons/Facebook"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  customHoverFocus: {
    width: 35,
    height: 35,
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#2e2e2e" },
    backgroundColor: "black",
    color: "white",
    marginRight: 5,
  },
  icon: {
    width: 20,
    height: 20,
  },
}))

const UniversityleftCol = ({
  title,
  img,
  phone,
  email,
  website,
  instagram,
  twitter,
  facebook,
}: UniversityLeftColumnProps) => {
  const classes = useStyles()

  let imgHeight = 0;
  let imgWidth = 0;

  try   {
    imgHeight = img.height
   imgWidth = img.width
}catch(e){
  // console.log(e)
}
  let width
  let height

  if (imgHeight > imgWidth) {
    width = "90px"
    height = "auto"
  } else if (imgHeight < imgWidth) {
    width = "auto"
    height = "90px"
  } else {
    width = "90px"
    height = "90px"
  }

  return (
    <Container>
      <Inner>
        <BackLink to={"/universities"}>
          <Arrow style={{ maxWidth: 100, height: 24 }} />
          <h4 style={{ fontSize: "1.25rem" }}>UNIVERSITIES</h4>
        </BackLink>
        <TextSection>
          <UniInfo>
            <GatsbyImage
              image={img}
              style={{ height: height, marginBottom: 20, width: width }}
              imgStyle={{ objectFit: "contain" }}
            />
            <h3
              style={{
                fontSize: "1.6rem",
                marginBottom: 28,
                textTransform: "uppercase",
                lineHeight: "31px",
              }}
            >
              <b>{title}</b>
            </h3>
          </UniInfo>
          <ContactInfo>
            <h3 style={{ fontSize: "2em", marginBottom: 5 }}>{phone}</h3>
            <p style={{ fontSize: "1rem", lineHeight: "1.6rem" }}>
              Email: <a href={"mailto:" + email}>{email}</a>
            </p>
            <p
              style={{
                fontSize: "1rem",
                lineHeight: "1.6rem",
                marginBottom: 5,
              }}
            >
              Website: <a href={website}>{website}</a>
            </p>
            <SocialIcons>
              {instagram && (
                <StyledIconButton aria-label="Instagram" href={instagram} className={classes.customHoverFocus}>
                  <InstagramIcon className={classes.icon} />
                </StyledIconButton>
              )}
              {twitter && (
                <StyledIconButton aria-label="Twitter" href={twitter} className={classes.customHoverFocus}>
                  <TwitterIcon className={classes.icon} />
                </StyledIconButton>
              )}
              {facebook && (
                <StyledIconButton aria-label="Facebook" href={facebook} className={classes.customHoverFocus}>
                  <FacebookIcon className={classes.icon} />
                </StyledIconButton>
              )}
            </SocialIcons>
          </ContactInfo>
        </TextSection>
      </Inner>
    </Container>
  )
}

export default UniversityleftCol

const StyledIconButton = styled(IconButton)`
  background-color: black !important;
  color: white;
  margin: 4px;

  .root {
    background-color: black !important;
  }
`

const Container = styled.div`
  /* display: none; */
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-column-start: 1;
    grid-column-end: 4;
    display: block;
    /* border: 1px solid red; */
    /* max-width: 264px; */
    position: relative;
    margin-bottom: 120px;
  }
`
const Inner = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    // Disabled for now as it conflicts with the modal
    position: sticky;
    left: 0;
    bottom: 0;
    top: 100px;
  }
`
const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const UniInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 60%;
  }
  @media (max-width: small) {
    width: 60%;
  }
`

const ContactInfo = styled.div`
  /* color: ${theme.text.second}; */
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: 0px;
  }
`

const Name = styled.h5`
  font-weight: 300;
  display: block;
  margin-top: -4px;
`
const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  color: black;
  margin-bottom: 18px;
  border-bottom: 3px solid black;
  padding-bottom: 30px;
  margin-bottom: 30px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const SocialIcons = styled.div`
  flex-direction: row;
`

// const Arrow = styled.img`
//   height: 40px;
//   transition: all 0.5s ease;
//   position: relative;
//   left: -10px;
//   animation: arrowUniversityTemplate ease 0.5s forwards 0.5s;
//   ${BackLink}:hover & {
//     transform: translateX(-20px);
//   }
// `

const Description = styled.p`
  /* color: ${theme.text.second}; */
  font-weight: 300;
`
