import React, { FunctionComponent } from "react"
import styled from "styled-components"
import CrossIcon from "../../assets/svg/GFW_cross.svg"
import { ButtonSecondary } from "../Buttons/Buttons"
import Carousel from "../Carousel"
interface UniversityRightColI {
  title: string
  phone: string
  email: string
  website: string
  google_maps_link: string
  full_address: string
  students
}

const UniversityRightCol: FunctionComponent<UniversityRightColI> = ({
  children,
  title,
  phone,
  email,
  website,
  google_maps_link,
  full_address,
  students,
}) => {
  const gradsThisUni = []

  students.map((item, index) => {
    if (item.student_profile.universitySchool.title.includes(title)) {
      gradsThisUni.push(item)
    }
  })

  return (
    <Container>
      {children}
      {/* {gradsThisUni.length>=1 &&(
           
        <CarouselContainer>

         <Carousel data={students} id={''}/>
         <ButtonContainer>

         <ButtonSecondary to ={'/search'}>View All Graduates</ButtonSecondary>
         </ButtonContainer>
        </CarouselContainer>

           
      )} */}
      {/* //@notlive */}

      <LocalFooter>
        <CrossIcon
          style={{
            maxWidth: 60,
            maxHeight: 60,
            paddingTop: 10,
            paddingBottom: 10,
            left: -5,
            position: "relative",
          }}
        />
        <h6>CONTACT DETAILS</h6>
        <h5
          style={{
            fontSize: "1.6rem",
            marginTop: 10,
            textTransform: "capitalize",
          }}
        >
          {title}
        </h5>
        <h5
          style={{
            fontSize: "1.6rem",
            marginTop: 10,
            textTransform: "capitalize",
          }}
        >
          {full_address}
        </h5>
        <a style={{ fontSize: "1.2rem", marginTop: 10, display: "block" }} href={google_maps_link}>
          Google Maps
        </a>
        <h2 style={{ marginTop: 30 }}>{phone}</h2>

        <p style={{ fontSize: "1.375rem", color: "#666666", marginTop: 20 }}>
          {/* <span>
            Email: <a href={"mailto:" + email}>{email}</a>
          </span> */}
          <span style={{ paddingLeft: 10, paddingRight: 10 }}>|</span>
          <span>
            Website: <a href={website}>{website}</a>
          </span>
        </p>
      </LocalFooter>
    </Container>
  )
}

export default UniversityRightCol

const Container = styled.div`
  grid-column-start: 5;
  grid-column-end: 13;
  /* border: 1px solid blue; */
  padding-left: 0;

  @media (min-width: 1024px) {
    /* padding-left: 11%; */
  }
`
const LocalFooter = styled.div`
  border-top: 3px solid black;
  margin-top: 80px;
  margin-bottom: 120px;
  padding-top: 30px;
`
const CarouselContainer = styled.div`
  max-width: 800px;
`

const ButtonContainer = styled.div`
  width: 40%;
  margin: 20px 0;
`
