import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

interface Item {
  title: string
  url: string
}
interface IBreadcrumb {
  data: Array<Item>
}
const Breadcrumb = ({ data }: IBreadcrumb) => {
  return (
    <Container>
      {data &&
        data.map((item, key: number) => {
          return (
            <React.Fragment key={key}>
              <LinkBreadcrumb to={item.url} key={key}>
                {item.title}
              </LinkBreadcrumb>
              <span>/</span>
            </React.Fragment>
          )
        })}
    </Container>
  )
}

export default Breadcrumb

const Container = styled.div`
  display: flex;
  align-items: center;
  /* padding: 10px 0px; */
  /* margin: 0 20px; */
  margin-bottom: 50px;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
  & span {
    margin: 0 8px;
    font-size: 9px;
    line-height: 11px;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      color: ${props => (props.color ? props.color : "#000000")};
    }

    &:last-of-type {
      display: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &::after {
    content: "";
    display: none;
    height: 1px;
    border-top: 2px solid #000000;
    position: absolute;
    bottom: 0;
    left: 20px;
    margin: 0 auto;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 30%;
      max-width: 263px;
      margin: 0;
      left: 0;
      display: block;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 0 3%;
  }
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 20px 0;
    margin: 0;
  }
`

const LinkBreadcrumb = styled(Link)`
  &,
  &:link,
  &:visited {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 11px;
    display: inline;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      line-height: 15px;
      color: ${props => (props.color ? props.color : "#4A4A4A")};
    }
  }

  &:hover,
  &:active {
    color: ${props => props.theme.colors.turquoise};
  }
  &:last-of-type {
    color: #000000;
    font-weight: 600;
    &:hover,
    &:active {
      text-decoration: none;

      cursor: default;
    }
  }
`
